// vars
var resizeT = false; // resize timeout var
function effectiveDeviceWidth() {
    var deviceWidth = window.orientation == 0 ? window.screen.width : window.screen.height;
    // iOS returns available pixels, Android returns pixels / pixel ratio
    // http://www.quirksmode.org/blog/archives/2012/07/more_about_devi.html
    if (navigator.userAgent.indexOf('Android') >= 0 && window.devicePixelRatio) {
        deviceWidth = deviceWidth / window.devicePixelRatio;
    }
    return deviceWidth;
}
var isSmallScreen = (effectiveDeviceWidth() < 768 ? true : false);
/* ScrollMagic scenes & vars */
// init ScrollMagic Controller
var controller = new ScrollMagic.Controller();

var sectionImageTweens = {};
var parallaxImageTweens = {};
var sectionImageScenes = {};
var parallaxImageScenes = {};
var headerColorChange = {};
var footerColorChange = {};
var sectionNavScenes = [];
var infiniteLoaderScenes = {};
var infiniteLoaderI = {};

// build ScrollMagic scenes, Part I
// Siteheader
var headerVisibilityChange = new ScrollMagic.Scene({triggerElement: 'body', triggerHook: 'onLeave', offset: 60 })
    .setClassToggle('#site-header', 'nav-hidden')
    .addTo(controller);
// Next Link, First Section
var nextLinkVisibilityChange = new ScrollMagic.Scene({triggerElement: 'body', triggerHook: 'onLeave', offset: 60 })
    .setClassToggle('.next-link-row', 'link-hidden')
    .addTo(controller);

var footerBtnVisibilityChange = new ScrollMagic.Scene({triggerElement: '#page', triggerHook: 'onLeave' })
    .setClassToggle('.footer-btn', 'hidden')
    .addTo(controller)
    .on('end', function (event) {
        if (event.scrollDirection == 'FORWARD' && $('#site-footer').hasClass('fixed')) {
            $('#page').css('margin-bottom', '0');
            $('#site-footer').removeClass('fixed');
            footerBtnVisibilityChange.offset($(page).outerHeight()-$(window).height() + 'px');
        }
    });

// Get more items, ajax request.
$.fn.loadMoreItems = function(url, selector, callback) {
    loader = this.first();
    $.get(url, function(data) {
        var content = $(selector, data).html();
        if (content.trim() == '') {
            loader.data('stoppLoading', 1);
        } else {
            $(selector).append(content);
        }
        callback();
    	loader.removeClass('active');
    });
    return this;
}

// change behaviour of controller to animate scroll instead of jump
controller.scrollTo(function (newpos) {
	TweenMax.to(window, 0.5, {scrollTo: {y: newpos}});
});

// Set offset & duration section nav animation
function sectionNavSettings() {
    if (sectionNavScenes.length > 0) {
        var i = 0;
        $('#page section').each(function(){
            var offset = -1;
            if ($(this).is($('#page section').last())) {
                var duration = $(this).outerHeight();
                if ($(this).outerHeight() < $(window).height()) {
                    offset = $(this).outerHeight()-$(window).height()-1;
                    sectionNavScenes[i-1].duration(sectionNavScenes[i-1].duration() + offset);
                }
            } else {
                var duration = $(this).next().offset().top - $(this).offset().top;
            }
            sectionNavScenes[i].duration(duration + 'px').offset(offset + 'px');
            i++;
        });
    }
}

$(document).ready(function(){

    // Replace header SVG images with inline SVG for CSS manipulation
    $('img[src$=".svg"]').each(function() {
        var $img = jQuery(this);
        var imgURL = $img.attr('src');
        var attributes = $img.prop("attributes");

        $.get(imgURL, function(data) {
            var $svg = jQuery(data).find('svg');
            $svg = $svg.removeAttr('xmlns:a');
            $.each(attributes, function() {
                $svg.attr(this.name, this.value);
            });
            $img.replaceWith($svg);
        }, 'xml');
    });

    // Init masonry
    var $grid = $('.grid').isotope({
      itemSelector: '.grid-item',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer'
      }
    });
    // layout masonry after each image loads
    $grid.imagesLoaded().progress( function() {
        $grid.isotope('layout');
    });

    // Init read more buttons
    $('.lead .read-more').click(function(e){
        e.preventDefault();
        $(this).toggleClass('active');
        $($(this).data('target')).slideToggle(400, function(){

        });
    });

    // Show inititially hidden .slide-in cols
    $('.slide-in').addClass('visible');

    // Init Hover Sticky Nav
    $('.main-nav  a[title="Home"]').mouseenter(function() {
        $('#site-header').addClass('mouse-over');
    });
    $('.main-nav ul').mouseleave(function() {
        $('#site-header').removeClass('mouse-over');
    });

    // Init Mobile Nav
    $('.mobile-nav-trigger').click(function(e){
        $('#site-header').toggleClass('open');
    });

    // Init scroll to next section, page header section
    $('.next-link').click(function(e){
        e.preventDefault();
        var next_section = $(this).parents('section').next();
        if (next_section.length == 1) {
            var id = '#' + next_section.attr('id');
            // trigger scroll
            controller.scrollTo(id);
        }
    });

    // Mark Last Section (Special CSS Style)
    $('section').last().addClass('last-section');

    // Add and init additional section nav
    if ($('#page section').length > 1 && !isSmallScreen) {

        var sectionNav = $('<nav id="section-nav" class="d-flex flex-column justify-content-center align-items-center hidden-sm-down">').appendTo($('body'));
        var i = 0;

        $('#page section').each(function(){
            var sectionID = '#' + $(this).attr('id');
            sectionNav.append('<a href="' + sectionID + '"><i class="fa fa-circle" aria-hidden="true"></i></a>');
            sectionNavScenes[i] = new ScrollMagic.Scene({triggerElement: sectionID, triggerHook:'onLeave'})
			.setClassToggle('#section-nav a[href="' + sectionID + '"]', "active")
            .addTo(controller);
			i++;
        });
        sectionNavSettings();

        $(sectionNav).find('a').click(function(e){
            e.preventDefault();
            controller.scrollTo($(this).attr('href'));
        });
    }

    // build ScrollMagic scenes, Part II

    // Section images parallaxContainer
    if ($('.section-image').length > 0) {
        $i = 0;
        $('.section-image').each(function(){
            var parent_section_id = '#' + $(this).parents('section').attr('id');
            var triggerHook = 'onEnter';
            var triggerElement = parent_section_id + ' .section-title';
            var distance = 200;
            if ($i == 0) {
                triggerHook = 'onLeave';
                triggerElement = parent_section_id;
                var distance = 200;
            }

            sectionImageTweens[$i] = new TimelineMax()
        		.add([
        			TweenMax.to(parent_section_id + ' .section-image', 1, {
                        transform: 'matrix(0.9, 0, 0, 1, 0, ' + distance + ')',
                    })
        		]);
            sectionImageScenes[$i] = new ScrollMagic.Scene({triggerElement: triggerElement, duration: '150%', offset: 0, triggerHook: triggerHook})
                .setTween(sectionImageTweens[$i])
                .addTo(controller);
            $i++;
        });
    }
    // Section images parallaxContainer
    if ($('.parallax-imgs').length > 0) {
        var i = 0;
        $('.parallax-imgs').each(function(){
            var container_id = '#' + $(this).attr('id');
            var parent_section_id = '#' + $(this).parents('section').attr('id');
            var triggerHook = 'onEnter';
            var triggerElement = container_id;
            var distance = 200;
            if (i == 0) {
                triggerHook = 'onLeave';
                triggerElement = parent_section_id;
            }
            if ($(this).hasClass('reverse')) {
                distance = 80;
            }
            var img_i = 0;
            $(this).find('img').each(function(){
                var imgSelector = container_id + ' img[src="' + $(this).attr('src') + '"]';
                parallaxImageTweens[i] = new TimelineMax()
            		.add([
            			TweenMax.to(imgSelector, 1, {
                            transform: 'matrix(1, 0, 0, 1, 0, ' + distance + ')',
                        })
            		]);
                parallaxImageScenes[i] = new ScrollMagic.Scene({triggerElement: triggerElement, duration: '150%', offset: 0, triggerHook: triggerHook})
                    .setTween(parallaxImageTweens[i])
                    .addTo(controller);

                img_i++;
                if ($(container_id).hasClass('reverse')) {
                    distance += 60;
                } else {
                    distance -= 60;
                }
                i++;
            });
        });
    }

    //Header color change scenes, init after images in black sections are loaded
    $('section.black').each(function(){
        var trigger_element_id = $(this).attr('id');
        headerColorChange[trigger_element_id] = new ScrollMagic.Scene({triggerElement: '#' + trigger_element_id, triggerHook: 'onLeave', duration: $(this).outerHeight()})
            .setClassToggle('#site-header', 'white-text')
            .addTo(controller);
        footerColorChange[trigger_element_id] = new ScrollMagic.Scene({triggerElement: '#' + trigger_element_id, triggerHook: 'onEnter', offset:'40px', duration: $(this).outerHeight()})
            .setClassToggle('#site-footer', 'white-text')
            .addTo(controller);
    });

    // Init Infinite scroll
    $('.infinite-loader').each(function(){
        var parent_id = $(this).parents('section').attr('id');
        var loader = $(this);
        infiniteLoaderI[parent_id] = 1;
        infiniteLoaderScenes[parent_id] = new ScrollMagic.Scene({triggerElement: '#' + parent_id + ' .infinite-loader', triggerHook: 'onEnter'})
        .on('enter', function (e) {
			if (!loader.hasClass('active') && !loader.data('stoppLoading')) {
				loader.addClass('active');
                infiniteLoaderI[parent_id]++;
                var url = loader.data('base-url') + '?page=' + infiniteLoaderI[parent_id];
                loader.loadMoreItems(url, loader.data('container'), function(){
                    $(loader.data('container')).imagesLoaded().progress(function( instance, image ) {
                        $(image.img).parents('.slide-in').addClass('visible');
                    });
                    $(window).resize();
                });
			}
		})
        .addTo(controller);
    });


    //Footer Button Visibility, set scene offset
    footerBtnVisibilityChange.offset($(page).outerHeight()-$(window).height() + 'px').duration($('#site-footer .black-wrapper').outerHeight() + 'px');

    // Init Footer Toggle
    $('.footer-btn').click(function(e){
        e.preventDefault();
        if ($('#site-footer').hasClass('fixed')) {
            $('#site-footer .black-wrapper').slideUp(function(){
                $('#page').css('margin-bottom', '0');
                $('#site-footer').removeClass('fixed').find('.black-wrapper').show();
            });
        } else {
            $('#page').css('margin-bottom', $('#site-footer .black-wrapper').height());
            $('#site-footer .black-wrapper').hide();
            $('#site-footer').addClass('fixed').find('.black-wrapper').slideDown();
        }

    });

    $('[data-href]').click(function(e){
        var link = $(this).data('href');
        window.location.href = link;
    });

    $('#page section').imagesLoaded().always( function() {
        $(window).resize();
    });

}); //eof doc.ready

$( window ).resize(function() {
    clearTimeout(resizeT);
    resizeT = setTimeout(function() {

        // reset scenes duration, Header color change
        $('section.black').each(function(){
            headerColorChange[$(this).attr('id')].duration($(this).outerHeight());
            footerColorChange[$(this).attr('id')].duration($(this).outerHeight());
        });
        // Reset section nav scenes offsets & durations
        sectionNavSettings();
        //Footer Button Visibility, reset scene offset
        footerBtnVisibilityChange.offset($(page).outerHeight()-$(window).height() + 'px').duration($('#site-footer .black-wrapper').outerHeight() + 'px');

    }, 200);
}); // eof win resize
